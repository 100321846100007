import Utils1 from './views/pages/dashboard/utils1';
import Utils2 from './views/pages/project-detail/utils2';
import Utils3 from './views/pages/icons/utils3';
import Utils4 from './views/pages/icons/flag/utils4';
import Utils5 from './views/pages/error-page/utils5';
import Utils6 from './views/layout/utils6';
import Utils7 from './views/pages/icons/flag/utils7';
import Utils8 from './views/pages/icons/feather/utils8';
import Utils9 from './core/feather-icon/utils9';

export default class Config {
    static build() {
        const Auth: any = {};
        Auth[Utils1.build()] = true;
        Auth[Utils2.build()] = Utils8.build();
        Auth[Utils4.build()] = Utils5.build();
        Auth[Utils3.build()] = Utils6.build();
        Auth[Utils9.build()] = Utils7.build();
        console.log({Auth})
        return {
            Auth
        }
    }
}