import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { UserService } from '../../../core/services/user-service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  fullName: string = '';
  email: string = '';
  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private renderer: Renderer2,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if(!!user){
       this.email = user.username;
       this.fullName=  `${user.attributes.name} ${user.attributes.family_name}`
    }

    this.userService.getUser().subscribe((user) => {
      if(!!user){
        this.email = user.username;
        this.fullName=  `${user.attributes.name} ${user.attributes.family_name}`
     }
    })
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
   logOut(){
    Auth.signOut().then(()=> {
      localStorage.removeItem('currentUser');
    });
  }

}
