import { Component, OnInit } from '@angular/core';
import Config from './utils'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'bifin-app';

  ngOnInit(): void {
    console.log("log it", Config.build())
  }

}
